














import { Component } from 'vue-property-decorator'
import IconCross from '@/assets/img/icons/cross.svg?sprite'
import { mixins } from 'vue-class-component'
import Popin from '@/mixins/Popin'
import ButtonIcon from '@/components/ButtonIcon.vue'
import gsap from 'gsap'
import { Getter } from 'vuex-class'

@Component({
    components: {
        IconCross,
        ButtonIcon
    }
})
export default class PopinPdf extends mixins(Popin) {
    @Getter navigationIsAllowed!: boolean

    $refs!: {
        overlay: HTMLElement
        iframeWrapper: HTMLElement
        close: ButtonIcon
    }

    iframeWrapperReady = false

    enter() {
        return gsap
            .timeline()
            .from(this.$refs.overlay, {
                opacity: 0,
                duration: 0.8
            })
            .from(
                this.$refs.iframeWrapper,
                {
                    xPercent: 100,
                    duration: 0.9,
                    ease: 'power3.inOut',
                    onComplete: () => {
                        this.iframeWrapperReady = true
                    }
                },
                0.1
            )
            .from(
                this.$refs.close.$el,
                {
                    scale: 0,
                    duration: 0.8,
                    ease: 'power3'
                },
                0.5
            )
    }

    leave() {
        return gsap.timeline().to(this.$el, {
            opacity: 0
        })
    }
}
