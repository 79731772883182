





































import { Component, Watch } from 'vue-property-decorator'
import Slide from '@/mixins/Slide.ts'
import { mixins } from 'vue-class-component'
import IconBurger from '@/assets/img/icons/burger.svg?sprite'
import MediaContent from '@/components/MediaContent.vue'
import Markdown from '@/components/Markdown.vue'
import Color from '@/constants/color'
import ArrowDownNextSlide from '@/components/ArrowDownNextSlide.vue'
import ButtonIcon from '@/components/ButtonIcon.vue'
import PdfViewer from '@/components/PopinPdf.vue'
import ScrollerElement, { ScrollerElementAnimationEvent } from '@/components/ScrollerElement.vue'
import MutationRemoteType from '@/constants/mutation-remote-type'
import ModuleNamespace from '@/constants/module-namespace'
import { Getter } from 'vuex-class'

@Component({
    components: {
        ScrollerElement,
        IconBurger,
        MediaContent,
        Markdown,
        ArrowDownNextSlide,
        ButtonIcon,
        PdfViewer
    }
})
export default class SlidePdfViewer extends mixins(Slide) {
    @Getter navigationIsAllowed!: boolean

    $refs!: {
        background: MediaContent
        overTitle: HTMLElement
        body: Markdown
        button: ButtonIcon
    }

    get walkerItem() {
        return this.walker.item as SlideWalkerItemPdfViewer
    }

    get backgroundMedia(): RoadizDocument | undefined {
        return this.walkerItem.backgroundMedia?.[0]
    }

    populateAppear(timeline: GSAPTimeline) {
        const textElements: Array<HTMLElement> = []

        if (this.$refs.overTitle) textElements.push(this.$refs.overTitle)
        if (this.$refs.body) textElements.push(this.$refs.body.$el as HTMLElement)
        if (this.$refs.button) textElements.push(this.$refs.button.$el as HTMLElement)

        if (this.$refs.background) {
            timeline.fromTo(
                this.$refs.background.$el,
                {
                    opacity: 0.8,
                    scale: 1.2
                },
                {
                    opacity: 1,
                    scale: 1,
                    duration: 2.4,
                    ease: 'power2'
                },
                0.5
            )
        }

        if (textElements.length) {
            timeline.fromTo(
                textElements,
                {
                    opacity: 0,
                    y: 60
                },
                {
                    opacity: 1,
                    y: 0,
                    duration: 1.2,
                    ease: 'power3',
                    stagger: 0.1
                },
                0.8
            )
        }
    }

    populateEnterY({ timeline }: ScrollerElementAnimationEvent) {
        const textElements: Array<HTMLElement> = []

        if (this.$refs.overTitle) textElements.push(this.$refs.overTitle)
        if (this.$refs.body) textElements.push(this.$refs.body.$el as HTMLElement)
        if (this.$refs.button) textElements.push(this.$refs.button.$el as HTMLElement)

        if (this.$refs.background) {
            timeline.fromTo(
                this.$refs.background.$el,
                {
                    opacity: 0.8,
                    scale: 1.2
                },
                {
                    opacity: 1,
                    scale: 1,
                    duration: 1,
                    ease: 'none'
                },
                0
            )
        }

        if (textElements.length) {
            timeline.fromTo(
                textElements,
                {
                    opacity: 0,
                    y: 60
                },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.5,
                    ease: 'none',
                    stagger: 0.1
                },
                0.5
            )
        }
    }

    onButtonClick() {
        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.POPIN_DOCUMENT, this.walkerItem.pdf[0])
    }

    @Watch('walker', { immediate: true })
    onWalkerChange() {
        this.color = (this.walker.item as SlideWalkerItemPdfViewer).invertContrast ? Color.DARK : Color.LIGHT
    }
}
